import type { AppProps } from "next/app";
import Head from "next/head";
import React from "react";
import "./shell.css";

const CustomApp: React.FC<AppProps> = (props) => {
  const { Component, pageProps } = props;

  return (
    <>
      <Head>
        <title>Toolbox App</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <Component {...pageProps} />
    </>
  );
};

export default CustomApp;
